<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <DropdownTranslate />
              <h1>{{ $t('auth.validEmail.title') }}</h1>
              <p>
                {{ $t('auth.validEmail.description') }}
              </p>
              <v-form v-on:submit.prevent="validationEmail">
                <v-text-field
                  v-model="email"
                  :label="$t('auth.emailAddress')"
                  :rules="[rules.email]"
                  backgroundColor="white"
                  solo
                  append-icon="mdi-account-outline"
                ></v-text-field>
                <v-text-field
                  v-model="code"
                  append-icon="fingerprint"
                  :label="$t('auth.validEmail.code')"
                  :rules="[rules.required]"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-layout align-start justify-center row fill-height>
                  <v-btn
                    type="submit"
                    class="white--text"
                    color="primary">{{ $t('auth.validEmail.button') }}
                  </v-btn>
                </v-layout>
                <div class="my-6">
                  <p
                    class="error--text"
                    v-if="
                      error">{{ $t('auth.validEmail.error') }}
                  </p>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ValidEmail',
  data() {
    return {
      email: decodeURIComponent(this.$route.query.email || ''),
      code: this.$route.query.validationToken || '',
      password: '',
      error: false,
      rules: {
        required: (value) => !!value || this.$t('misc.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          // Regex find on text-field vuetify
          return pattern.test(value) || this.$t('misc.invalidEmail');
        },
      },
    };
  },
  methods: {
    async validationEmail() {
      try {
        await this.$store
          .dispatch('auth/VALIDATION_EMAIL', {
            validationToken: this.code,
            email: this.email,
          });
        this.$router.push('/');
      } catch (error) {
        this.error = error.message;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('auth.validEmail.title'),
    };
  },
};
</script>
